export default {
  'categories': 'Catégories',
  'about': 'À propos',
  'about-info':
    'Courses en ligne avec mon-marché.fr : Alliez gain de temps et savoir-faire français en choisissant notre service de livraison de produits frais et de qualité, livrés directement chez vous. \nUne expérience de courses en ligne pensée pour vous.',
  'contact-us': 'Contactez-nous',
  'home-footer-text': 'Faire ses courses en ligne',
  'openings': 'Nous répondons à vos questions',
  'opening-hours': '7 jours sur 7 de 9h à 22h',
  'by-chat': 'Chat',
  'download-apps': "Téléchargez l'application",
  'ratings-and-download': 'Application et avis clients',
  'our-engagements': 'Nos engagements',
  'our-faq': 'Notre FAQ',
  'faq': 'FAQ',
  'our-blog': 'Notre Blog',
  'join-us': 'Nous rejoindre',
  'sitemap': 'Plan du site',
  'mm-shop': 'Le Magasin',
  'referral': 'Parrainage',
  'delivery-zones': 'Nos zones de livraison',
  'cgv': 'CGV et mentions légales',
  'special-operations-policies': 'Nos offres spéciales',
  'contact-form': 'Formulaire de contact',
  'cookies': 'Informations sur les cookies',
  'cookies-management': 'Gestion des cookies',
  'privacy': 'Gestion des données personnelles',
  'copyright': '%{domainUrl} © %{copyrightYear}',
  'version': 'Version',
  'who-we-are': 'Qui sommes-nous ?',
  'reinsurance': {
    'ultra-fresh': 'Produits ultra-frais',
    'ultra-fresh-short': 'Ultra-frais',
    'received': 'De nos 700 producteurs partenaires',

    'meticulous-selection': 'Sélection minutieuse',
    'season-variety': 'Des meilleures variétés de saison',

    'fair-price': 'Des prix justes',
    'promotions': "Pour tous et toute l'année",

    'delivery': 'Livraison 7J/7',
    'bike': 'À domicile dans tout Paris',
  },
  'alcohol-disclaimer': {
    'part-1': 'Interdiction de vente de boissons alcooliques aux mineurs de moins de 18 ans',
    'part-2': "La preuve de majorité de l'acheteur est exigée au moment de la vente en ligne.",
    'part-3': 'CODE DE LA SANTÉ PUBLIQUE : ART. L. 3342-1. L. 3342-3',
  },
};
