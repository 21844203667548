import { NextRouter } from 'next/router';

import { BasicProduct } from '~/src/common/components/GridCard/ProductCard/type';
import dayjs from '~/src/common/services/Date';
import { OrderCompletedProperties } from '~/src/common/services/Tracker/generated';
import { Cart, DeliverySlot, DeliveryZone } from '~/src/common/typings/cart';
import { Order } from '~/src/common/typings/order';
import { ItemDefinition } from '~/src/common/typings/product';
import { round } from '~/src/common/utils/number';
import { getPromoPercentage } from '~/src/common/utils/prices';
import {
  isFreeDeliveryBannerDisplayed,
  getCommonSlotEventProperties,
} from '~/src/common/utils/slots';
import {
  FinalizePrepay200,
  VerifyDelivery200CartDeliveryDeliveryZone,
  VerifyDelivery200CartDeliveryTimeSlot,
} from '~/src/queries/api-ecom/generated/api-ecom.schemas';

import Tracker from './Tracker';
import {
  AddToCartProductEventSource,
  AddToCartStatus,
  BookmarkListProperties,
  CartEventProperties,
  DeliveryOrderStatus,
  EventName,
  EventProperties,
  ExtraCartEventProperties,
  PickupType,
} from './types';

const UNTRACKED_PAGE = [
  '404',
  // les pages catégories et landing sont trackées directement depuis leur composant screen
  'produit',
  'categorie',
  'landing',
  'plan-du-site',
  'recherche',
  'checkout',
  'recette',
];

export const getEventBrowseValues: (
  url: string,
  query?: NextRouter['query'],
) => {
  eventName: EventName;
  eventProperties: EventProperties<EventName>;
} | null = (url, query) => {
  if (query?.q != null) return null;

  if (url === '/' || url === '/reset-password') {
    return {
      eventName: 'homepage viewed',
      eventProperties: {
        'is page viewed': true,
        'current page path': '/',
        'current page name': 'homepage',
        'current page type viewed': 'homepage',
      },
    };
  }

  if (url.startsWith('/account/')) {
    const eventName = 'user portal viewed';

    return {
      eventName,
      eventProperties: {
        'is page viewed': true,
        'current page path': url,
        'current page name': url.split('/').pop(),
        'current page type viewed': 'user portal',
      },
    };
  }

  const splitUrl = url.split('/');

  if (!UNTRACKED_PAGE.some(untrackedPage => untrackedPage === splitUrl[1])) {
    const eventName = 'brand page viewed';

    const hasDeepRouting = splitUrl.length > 1;

    return {
      eventName,
      eventProperties: {
        'is page viewed': true,
        'current page path': url,
        'current page name': hasDeepRouting ? splitUrl.pop() : url.substring(1),
        'current page type viewed': 'brand page',
      },
    };
  }

  // Les pages ne sont pas trackées par défaut
  return null;
};

type getBookmarkEventMappingPayload = {
  product: Pick<BasicProduct, 'name' | 'pimCategoryName' | 'canonicalId' | 'rating' | 'sku'> & {
    itemPrice?: number;
  };
  categoryName?: string;
  subcategoryName?: string;
  productEventSource: AddToCartProductEventSource;
};

export const getBookmarkEventMapping = ({
  product,
  categoryName,
  subcategoryName,
  productEventSource,
}: getBookmarkEventMappingPayload): BookmarkListProperties => ({
  'product name': product?.name,
  'product id': product?.canonicalId,
  'product category': categoryName || '',
  'product subcategory': subcategoryName || '',
  'product pim': product?.pimCategoryName || '',
  'product price': (product?.itemPrice ?? 0) / 100,
  'product event source': productEventSource,
  'product rating': product?.rating?.average,
  'nb of ratings': product?.rating?.nbRatings,
  'SKU': product?.sku,
});

export const DELIVERY_ORDER_STATUS: Partial<Record<Order['state'], DeliveryOrderStatus>> = {
  prepaying: 'PRÉ-PAYÉE',
  prepaid: 'PRÉ-PAYÉE',
  preparing: 'EN PRÉPARATION',
  prepared: 'PRÉPARÉE',
  approvedByClient: 'PRÉPARÉE',
  ready: 'DISPONIBLE',
  cart: 'EN LIVRAISON',
  readyToDeliver: 'EN LIVRAISON',
  inTransit: 'EN LIVRAISON',
  delivered: 'LIVRÉE',
  paid: 'PAYÉE',
};

export const PickupTypeValues: Record<string, PickupType> = {
  remotePickup: 'remote',
  delivery: 'shop',
  pickup: 'shop',
  onSitePickup: 'on site',
};

export const getDeliveryZoneProperties = (zone: {
  shopName?: string;
  type?: 'delivery' | 'pickup' | 'remotePickup' | 'onSitePickup';
  pickupType?: 'drive' | 'collect';
  name?: string;
}) => ({
  'shop': zone.shopName,
  'shipping type':
    !zone.type || zone.type !== 'delivery' ? ('pickup' as const) : ('delivery' as const),
  'pickup type': zone.type ? PickupTypeValues[zone.type] : '-',
  'pickup mode': zone.type === 'delivery' || !zone.pickupType ? ('-' as const) : zone.pickupType,
  'delivery zone/pick-up point': zone.name || '-',
});

const computeDeltaValues = (slots?: DeliverySlot[], isAuto = true, daySlots?: DeliverySlot[]) => {
  if (!slots) {
    return {};
  }

  const minDeltaDay = !isAuto ? Math.min(...slots.map(ds => ds.rate.deltaDay)) / 100 : undefined;
  const maxDeltaDay = !isAuto ? Math.max(...slots.map(ds => ds.rate.deltaDay)) / 100 : undefined;

  const minDeltaSlot =
    daySlots && daySlots.length
      ? Math.min(...daySlots.map(ds => ds.rate.deltaSlot)) / 100
      : undefined;
  const maxDeltaSlot =
    daySlots && daySlots.length
      ? Math.max(...daySlots.map(ds => ds.rate.deltaSlot)) / 100
      : undefined;

  return {
    minDeltaDay,
    maxDeltaDay,
    minDeltaSlot,
    maxDeltaSlot,
  };
};

export type ReasonForDeliveryChange =
  | 'new address'
  | 'full'
  | 'expired'
  | 'excluded'
  | 'deleted'
  | 'unknown'
  | 'manual';

/**
 * Wrapper pour l'évènement 'shipping slot validated' envoyant les
 * bonnes propriétés en fonction de la zone et du slot
 * @param slot
 * @param zone
 * @param validationMode surcharge de la propriété 'validation mode', par défaut à 'auto'
 * @param deltaShippingSlotStartTime surcharge de la propriété 'delta shipping slot start time' non définie par défaut
 * @param daySlots slots du jour uniquement, pour plus de précision dans les données trackées
 */
export const sendShippingSlotValidatedEvent = ({
  timeSlot,
  deliveryZone,
  isAuto = true,
  deltaShippingSlotStartTime = undefined,
  daySlots = undefined,
  reasonForChange = 'unknown',
}: {
  timeSlot: DeliverySlot | VerifyDelivery200CartDeliveryTimeSlot;
  deliveryZone?: DeliveryZone | VerifyDelivery200CartDeliveryDeliveryZone;
  isAuto?: boolean;
  deltaShippingSlotStartTime?: number | string;
  daySlots?: DeliverySlot[];
  reasonForChange: ReasonForDeliveryChange;
}) => {
  const deliverySlots =
    deliveryZone && 'deliverySlots' in deliveryZone ? deliveryZone.deliverySlots : undefined;

  const deliveryAd = isFreeDeliveryBannerDisplayed(daySlots ?? deliverySlots) ? 'yes' : 'no';

  const { minDeltaDay, maxDeltaDay, minDeltaSlot, maxDeltaSlot } = computeDeltaValues(
    deliverySlots,
    isAuto,
    daySlots,
  );

  const slotsPrices = (daySlots || [])
    ?.filter(s => !s.isFull && !s.isExpired)
    ?.map(s => s.deliveryPricesWithDeltas?.[0].shippingAmount ?? 0);

  const minRateViewed =
    slotsPrices && slotsPrices.length ? Math.min(...slotsPrices) / 100 : undefined;
  const maxRateViewed =
    slotsPrices && slotsPrices.length ? Math.max(...slotsPrices) / 100 : undefined;

  if (deliveryZone) {
    Tracker.setUserProperties(getDeliveryZoneProperties(deliveryZone));
  }

  Tracker.sendEvent('shipping slot validated', {
    'reason for change': reasonForChange,
    'shipping type': deliveryZone?.type === 'delivery' ? 'delivery' : 'pickup',
    'validation mode': isAuto ? 'auto' : 'manual',
    'delta shipping slot start time': deltaShippingSlotStartTime,
    'pickup type': deliveryZone?.type ? PickupTypeValues[deliveryZone?.type] : '-',
    'pickup mode':
      deliveryZone?.type === 'delivery' || !deliveryZone?.pickupType
        ? '-'
        : deliveryZone.pickupType,
    'shipping cost delta day': (timeSlot?.rate?.deltaDay || 0) / 100,
    'shipping cost delta slot': (timeSlot?.rate?.deltaSlot || 0) / 100,
    'shipping cost viewed': (timeSlot?.rate?.total || 0) / 100,
    'shipping cost delta day min': minDeltaDay,
    'shipping cost delta day max': maxDeltaDay,
    'shipping cost delta slot min': minDeltaSlot,
    'shipping cost delta slot max': maxDeltaSlot,
    'shipping cost viewed min': minRateViewed,
    'shipping cost viewed max': maxRateViewed,
    'free delivery ad': !isAuto ? deliveryAd : undefined,
    'delivery zone/pick-up point': deliveryZone?.name ?? '',
    'shop': deliveryZone && 'shop' in deliveryZone ? deliveryZone.shop.name : '',
    ...(deliverySlots || daySlots
      ? // @ts-expect-error TS n'arrive pas à gérer correctement ??
        getCommonSlotEventProperties(daySlots ?? deliverySlots, timeSlot)
      : {}),
  });
};

/** Mise à jour de l'utilisateur lors du changement de Delivery Slot */
export const setTimeSlotUserProperties = (timeSlot: Pick<DeliverySlot, 'from' | 'to'>) => {
  const today = dayjs().set('hour', 0).set('minutes', 0);
  const fromDayJs = dayjs(timeSlot.from);
  const startTime = fromDayJs.format('HH[h]mm');
  const endTime = dayjs(timeSlot.to).format('HH[h]mm');
  const orderType =
    'initialOrder' in timeSlot && timeSlot.initialOrder ? 'additional order' : 'new order';

  Tracker.setUserProperties({
    'shipping slot': `${startTime}-${endTime}`,
    'shipping slot start time': startTime,
    'shipping slot end time': endTime,
    'shipping slot days': fromDayJs.diff(today, 'day'),
    'order type': orderType,
  });
};

export const getCartEventName = (
  previousQuantity: number,
  newQuantity: number,
): {
  eventName: 'click add to cart' | 'click remove product';
  status: AddToCartStatus;
} => {
  if (newQuantity > previousQuantity) {
    return { eventName: 'click add to cart', status: 'product added' };
  }

  if (newQuantity < previousQuantity) {
    return { eventName: 'click remove product', status: 'product removed' };
  }

  return { eventName: 'click add to cart', status: 'product added' };
};

type getCartEventMappingPayload = {
  product: Pick<
    BasicProduct,
    | 'name'
    | 'pimCategoryName'
    | 'canonicalId'
    | 'promo'
    | 'rating'
    | 'packSize'
    | 'consumptionDate'
    | 'sku'
  > & {
    itemPrice?: number;
    itemDefinition?: ItemDefinition;
  };
  quantity: number;
  categoryName?: string;
  subcategoryName?: string;
  cart?: Cart;
  status: AddToCartStatus;
  productEventSource: AddToCartProductEventSource;
  otherAnalyticsProperties?: ExtraCartEventProperties;
};

export const getCartEventMapping = ({
  product,
  quantity,
  cart,
  status,
  productEventSource,
  categoryName,
  subcategoryName,
  otherAnalyticsProperties = {},
}: getCartEventMappingPayload): CartEventProperties => ({
  'product name': product.name,
  'product id': product.canonicalId,
  'product category': categoryName || otherAnalyticsProperties['category name'] || '',
  'product subcategory': subcategoryName || otherAnalyticsProperties['subcategory name'] || '',
  'product pim': product.pimCategoryName,
  'product price': (product.itemPrice ?? 0) / 100,
  'product quantity': quantity,
  'product consumption date': product.consumptionDate
    ? dayjs(product.consumptionDate).format('DD/MM/YY')
    : undefined,
  'product rating': product.rating?.average,
  'nb of ratings': product.rating?.nbRatings,
  'order prepaid': (cart?.price?.quotation?.preauthorization ?? 0) / 100, // Total valeur panier
  'product event source': productEventSource, // Element source
  'add or remove status': status,
  'cart id': cart?.id ?? undefined,
  'shop': cart?.delivery?.shop?.name,
  'promo percent':
    product.promo != null ? getPromoPercentage(product.promo, product.itemPrice) : undefined,
  'promo mechanism': product.promo?.mechanism,
  'promo triggering quantity': product.promo?.conditions?.nthQuantity,
  'mdv3 granularity amount':
    product?.packSize && product.packSize > 1 ? product.packSize : undefined,
  'SKU': product?.sku,
  ...otherAnalyticsProperties,
});

export const sendCompleteOrderViewedEvent = (
  id: Cart['id'] | undefined,
  price: Cart['price'] | undefined,
  products: Cart['products'] | undefined,
  initialOrder: Cart['initialOrder'] | undefined,
) => {
  Tracker.sendEvent('checkout additional order viewed', {
    'cart id': id,
    'order id': initialOrder?.id ?? '',
    'order prepaid': round((price?.quotation.preauthorization ?? 0) / 100, 2),
    'total cart size': products?.length ?? 0,
    'total cart': round((price?.quotation.net ?? 0) / 100, 2),
  });
};

export const sendOrderSubmittedEvent = (
  paymentMethod: string,
  cart: Cart,
  isCompleteOrderActivated: boolean,
) => {
  const isCompleteOrder = isCompleteOrderActivated && cart?.initialOrder != null;
  const eventName = isCompleteOrder ? 'additional order submitted' : 'order submitted';
  const additionalProperties = isCompleteOrder
    ? { 'order id': cart?.initialOrder?.id ?? '' }
    : {
        'total discounts': round((cart?.price?.quotation.discount ?? 0) / 100, 2),
        'code': cart.coupons.map(c => c.name),
        'shipping method': 'standard',
        'total shipping': round((cart?.price?.quotation.shipping ?? 0) / 100, 2),
      };

  Tracker.sendEvent(eventName, {
    'cart id': cart?.id ?? undefined,
    'order prepaid': round((cart?.price?.quotation.preauthorization ?? 0) / 100, 2),
    'total cart size': cart?.products.length ?? 0,
    'total cart': round((cart?.price?.quotation.net ?? 0) / 100, 2),
    'payment method': paymentMethod,
    ...additionalProperties,
  });
};

export const sendOrderCompletedEvent = (
  paidOrder: FinalizePrepay200['paidOrder'],
  cart: FinalizePrepay200['newCart'],
  isCompleteOrderActivated: boolean,
  paymentMethod: string,
) => {
  const isCompleteOrder = isCompleteOrderActivated && cart?.initialOrder != null;
  const { id, price, coupons, products } = paidOrder;

  if (price?.quotation?.preauthorization == null) return null;

  const totalDiscounts = price.quotation.discount;
  const totalShipping = price.quotation.shipping || 0;
  const deltaDayCharged = price.quotation.deltaDay || 0;
  const deltaSlotCharged = price.quotation.deltaSlot || 0;

  const eventName = isCompleteOrder ? 'additional order completed' : 'order completed';
  const packingReductionStatus: OrderCompletedProperties['packing reduction status'] = cart?.packing
    ?.reducePacking
    ? 'on'
    : 'off';
  const missingProductStatus: OrderCompletedProperties['unavailable products replacement status'] =
    cart?.replaceMissingProducts ? 'on' : 'off';
  const additionalProperties = isCompleteOrder
    ? {
        'order id': cart?.initialOrder?.id ?? '',
        'number of additional order': paidOrder.cartIds.length - 1,
      }
    : {
        'shipping method': 'standard',
        'total discounts': round(totalDiscounts / 100, 2),
        'total shipping': round(totalShipping / 100, 2),
        'packing reduction status': packingReductionStatus,
        'unavailable products replacement status': missingProductStatus,
        'shipping cost delta day charged': round(deltaDayCharged / 100, 2),
        'shipping cost delta slot charged': round(deltaSlotCharged / 100, 2),
        'code': coupons.map(c => c.name),
      };

  Tracker.sendEvent(eventName, {
    'cart id': id,
    'total cart size': products.length,
    'order prepaid': round(price.quotation.preauthorization / 100, 2),
    'payment method': paymentMethod,
    ...additionalProperties,
  });
};
